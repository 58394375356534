<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
       {{$store.state.user.routerName}}
       <div class="ml-1 mt-1  d-flex" >
   <!-- <b-link :to="`/apps/companies/edit/${$store.state.user.companyId}`" v-if="$router.currentRoute.name==='companies-candidate' | $router.currentRoute.name==='companies-edit'"><h4 class="mb-1">{{ $store.state.user.companyName }} </h4></b-link>  
    <p v-if="$router.currentRoute.name==='companies-candidate'">&nbsp; /  {{$store.state.user.title }}</p>  -->
     <slot name="breadcrumb" >
        <app-breadcrumb />
      </slot>
    </div>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      
 
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'

import UserDropdown from './components/UserDropdown.vue'
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import router from "@/router";

export default {

  components: {
    BLink,
    AppBreadcrumb,
    BNavbarNav,
 
    UserDropdown,
  },

 
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
